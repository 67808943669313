<template>
  <div class="main m-l">
    <h1 class="h1">{{$t('ChangePassword')}}</h1>
    <div class="change-password">
      <div class="form-item cl">
        <div class="label">{{$t('Current password')}}：</div>
        <div class="fl">
          <input class="ipt" type="password" autocomplete="off" :placeholder="$t('PleaseEnter')"
                 v-model.trim="oldPassword"/>
        </div>
      </div>
      <div class="form-item cl">
        <div class="label">{{$t('New password')}}：</div>
        <div class="fl">
          <input class="ipt" type="password" autocomplete="off" :placeholder="$t('PleaseEnter')"
                 v-model.trim="password"/>
        </div>
      </div>
      <div class="form-item cl">
        <div class="label">{{$t('Confirm new password')}}：</div>
        <div class="fl">
          <input class="ipt" type="password" autocomplete="off" :placeholder="$t('PleaseEnter')"
                 v-model.trim="confirmPassword"/>
          <div class="tips">{{$t('tipMsg')}}</div>
        </div>
      </div>
      <div class="form-item cl">
        <div class="label">&#x3000;</div>
        <div class="fl">
          <button class="button button-primary" type="button" :disabled="disableSubmit" @click="onSubmit">{{$t('Save')}}
          </button>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
  import {checkPassWord} from '../../../utils/validate';
  import {adminUpdatePassword} from '../../../api/admin-user';

  export default {
    name: 'AdminChangePassword',
    i18n: {
      messages: {
        'zh-cn': {
          tipMsg: '* 密码长度不少于6位元，由数字、字母或常用符号组成'
        },
        'zh-hk': {
          tipMsg: '* 密碼長度不少於6位元，由數字、字母或常用符號組成'
        },
        'en': {
          tipMsg: '* 密碼長度不少於6位元，由數字、字母或常用符號組成'
        }
      }
    },
    data() {
      return {
        oldPassword: '',
        password: '',
        confirmPassword: ''
      };
    },
    computed: {
      disableSubmit: function () {
        return !this.oldPassword || !this.password || !this.confirmPassword;
      }
    },
    methods: {
      onSubmit() {
        const data = {
          oldPassword: this.oldPassword,
          newPassword: this.password
        };

        if (!checkPassWord(data.newPassword)) {
          this.$message.warning('密碼長度不少於6位元，由數字、字母或常用符號組成');
          return false;
        }
        if (data.newPassword !== this.confirmPassword) {
          this.$message.warning('兩次輸入的密碼不一致');
          return false;
        }

        adminUpdatePassword(data).then(res => {
          this.$message.success(this.$t('SaveSuccessfully'));
          Object.assign(this.$data, this.$options.data());
        }).catch(() => {
        });
      }
    }
  };
</script>
<style scoped lang="less">
  .main{
    .h1{font-size:24px;}
  }
  .change-password{
    margin-top:40px;
    a.btn-submit{margin-left:96px;}
  }
  .form-item{
    margin-bottom:24px;
    .label{float:left;min-width:96px;line-height:32px;text-align:right;color:#321908;font-size:16px;}
    .ipt{
      width:300px;height:32px;padding:0 10px;color:#321908;font-size:14px;border:1px solid #d9d9d9;border-radius:4px;
      &:focus{border-color:#ff6f61;}
      &.w1{width:64px;margin-right:8px;}
    }
    .tips{margin-top:16px;color:#ff6f61;font-size:14px;}
  }
</style>
